/* 08.01. Blog One CSS */

.blog {
    overflow: hidden;
    height: 100%;
    transition: $transition;

    & .thumbnail {
        position: relative;
        overflow: hidden;

        & .image {
            display: block;

            & img {
                width: 100%;
                transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }

    & .info {
        position: relative;

        padding: 30px 0;

        & .title {
            font-size: 24px;
            line-height: 1.3;
            margin-bottom: 15px;
            // Responsive
            @media #{$large-mobile}{
                font-size: 18px;
                line-height: 1.3;
            }
            & a {
            }
        }

        & .desc {
            margin-top: 10px;
        }

        & .meta {
            font-size: 14px;

            display: flex;
            flex-wrap: wrap;

            margin-bottom: 10px;
            padding-left: 0;

            list-style: none;

            & li {
                margin-right: 28px;

                &:last-child {
                    margin-right: 0;
                }

                & i {
                    margin-right: 10px;
                }
            }
        }
    }

    &:hover {
        & .thumbnail {
            & .image {
                & img {
                    transform: scale(1.1);
                }
            }
        }
    }
}