/* 04.10 Pagination CSS */

.pagination {
    flex-wrap: wrap;
    margin: 0 -7px;
    // Responsive
    @media #{$extra-small-mobile}{
        margin: 0 -8px;
    }
    &.center {
        justify-content: center;
        align-items: flex-end;
    }

    & li {
        padding: 0 7px;
        // Responsive
        @media #{$extra-small-mobile}{
            margin: 0 -3px;
        }
        & a {
            font-size: 16px;
            font-weight: 400;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 45px;
            padding: 10px 15px;
            border: 1px solid #e7e7e7;
            text-transform: uppercase;

            color: $secondary;
            border-radius: 5px;
            // Responsive
            @media #{$extra-small-mobile}{
                width: 40px;
                height: 40px;
                padding: 10px 10px;
            }
            

            &:hover {
                background-color: $primary;
                color: $white;
            }

            &.active {
                cursor: default;
                pointer-events: none;

                color: $white;
                background-color: $primary;
            }
        }
    }
}